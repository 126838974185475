import React from 'react';

import './header.css';



  import headerImg from "../../assets/images/headerLogo2/header-img-logo2.svg"



const Header = () => {
  
  return (
    
       <section id="ponos">
        
            <div className="container header_main">
                <div className="row header-content">
                  
                    <div className="col-lg-6 col-md-12 col-sm-12 header-left">
                      <div className='text-wrapper'>
                                

                                <h1 className='title1'>Ponos Technology</h1>
                                <h1>performs <span className='nowrap'>top-notch</span> R&D of core blockchain infrastructures, specialized for ZKP protocols</h1>

                                {/*  comment- br before Proof */}
                                {/* <br className='header-line' /> */}
                               

                       
                      </div>
                      
                    </div>
                     <div className="col-lg-6 col-md-12 col-sm-12 header-right">
                        <div className='header-img-wrapper'>
                                <img  src={headerImg} alt="header-img" /> 
                           
                       
                        </div>
                        </div>  
                    
                </div> 
                
             
            </div>
          
           
              
        </section>
   
  )
}

export default Header
